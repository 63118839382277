import React from "react"
import SubLayout from "../components/Sublayout"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "../components/structural/Container"
import { FormattedMessage } from "react-intl"

import styled from "styled-components"

const Card = styled.div`
  max-width: 900px;
  width: calc(90% - 32px);
  background-color: white;
  border-radius: 10px;
  margin: auto;
  align-items: center;
  padding: 32px;
  text-align: center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;

  h4 {
    margin: 0;
    font-size: 22px;
    font-weight: 400;
  }

  p {
    margin-top: 0px;
    font-size: 16px;
  }
  .team-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 16px 16px 16px 16px;
    overflow: hidden;
  }

  .test {
    padding: 16px;
  }
`

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 200, maxHeight: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const names = [
  "Lion Weiß",
  "Harit Patel",
  "Lena Schindler",
  "Stefan Grifka",
  "Bianca Laakmann",
  "Philipp Lange",
  "Lena Kollertz",
  "Ashok Prabhu",
  "Hannah Schmid",
  "Rabea Gatz",
  "Jayani Patel",
  "Laura Gebert",
  "Meline Meinköhn",
  "Paul Feig",
  "Kathrin Bärnklau",
]

const roles = [
  "board",
  "board",
  "board",
  "board",
  "board",
  "board",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
]

const TeamImages = () => (
  <StaticQuery
    query={graphql`
      query {
        teamimgs: allFile(
          filter: { relativeDirectory: { eq: "team" } }
          sort: { order: ASC, fields: absolutePath }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return data.teamimgs.edges.map((image, index) => {
        return (
          <div className="test" key={index}>
            <GatsbyImage
              className="team-image"
              image={image.node.childImageSharp.gatsbyImageData}
              alt={names[index]}
            />
            <h4>{names[index]}</h4>
            <FormattedMessage tagName="p" id={"team." + roles[index]} />
          </div>
        )
      })
    }}
  ></StaticQuery>
)

class Team extends React.Component {
  render() {
    return (
      <SubLayout fixedHeader>
        <Container heading="team.heading">
          <Card>
            <TeamImages></TeamImages>
          </Card>
        </Container>
      </SubLayout>
    )
  }
}

export default Team
